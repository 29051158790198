/**
 * Gets the major version of a language code. E.g. the major version of 'en-US' is 'en'.
 * The major version of 'en' is 'en'.
 * @param {String} languageCode
 * @returns The major version of a language code
 */
export default function (languageCode: string) {
  if (!languageCode) {
    return null;
  }
  const langParts = languageCode.split('-', 2);
  return langParts[0];
}
