const colors = {
  orangeDarker: '#E55400',
  orange: '#FF8000',
  orangeMedium: '#FDB165',
  orangeLight: '#FCCC9C',
  orangeDisabled: '#E8C6AD',

  marine: '#000B4C',
  marineMedium: '#646C93',
  marineLight: '#9BA0B9',
  marineDisabled: '#9DA1B2',

  aquaDarker: '#B2BECF',
  aqua: '#DEEBF0',
  aquaMedium: '#E9F2F5',
  aquaLight: '#EFF5F7',
  aquaDisabled: '#ECF1F3',

  purpleDarker: '#570F89',
  purple: '#6D1099',
  purpleMedium: '#A56FC1',
  purpleLight: '#DECDE9',
  purpleDisabled: '#C5B4CF',

  white: '#FAFCFC',

  red: '#E73A3A',
  redLight: 'rgba(231, 58, 58, 0.2)',

  amber: '#FFCC00',
  amberLight: 'rgba(255, 204, 0, 0.2)',

  green: '#7DBF3A',
  greenLight: 'rgba(125, 191, 58, 0.2)',

  blue: '#3E9DCD',
  blueLight: 'rgba(62, 157, 205, 0.1)'
};

module.exports = { colors };
