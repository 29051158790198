import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("button", {
        class: _normalizeClass([{
                'tw-text-secondary': _ctx.level === 'primary',
                'tw-text-secondary-medium ': _ctx.level === 'secondary',
                'tw-text-body-1': _ctx.size === 'default',
                'tw-text-body-2': _ctx.size === 'small'
            }, "tw-underline tw-underline-offset-1 hover:tw-text-primary-darker focus:tw-outline-none"])
    }, [
        _renderSlot(_ctx.$slots, "default")
    ], 2));
}
