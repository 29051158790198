import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "tw-text-list-title tw-border-b tw-border-b-background tw-pb-2 tw-pl-2 tw-text-secondary"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.title)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
        _createElementVNode("ul", null, [
            _renderSlot(_ctx.$slots, "default")
        ])
    ], 64));
}
