import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "tw-flex" };
const _hoisted_2 = {
    class: "tw-text-module-name tw-self-end tw-pl-2 tw-pb-1",
    "data-test-id": "page-title"
};
const _hoisted_3 = { class: "tw-self-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_FrissLogo = _resolveComponent("FrissLogo");
    return (_openBlock(), _createElementBlock("header", _mergeProps({
        class: ["tw-top-0 tw-left-0 tw-right-0 tw-flex tw-h-16 tw-justify-between tw-bg-body tw-py-3 tw-pl-12 tw-pr-6 tw-text-secondary", _ctx.classes]
    }, _ctx.$attrs), [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
                class: "tw-flex tw-cursor-pointer",
                "data-test-id": "friss-logo",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.onLogoClicked && _ctx.onLogoClicked(...args)))
            }, [
                _createVNode(_component_FrissLogo)
            ]),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
        ])
    ], 16));
}
