export const scoreColors: Record<string, string> = {
  red: '#E73A3A',
  amber: '#FFCC00',
  green: '#7DBF3A',
  blue: '#3E9DCD',
  grey: '#9BA1BA',
  black: '#333333',
  navy: '#035397',
  purple: '#6D1099',
  pink: '#FF67E7',
  yellow: '#EEED0F',
  olive: '#BABD42'
};

/** Resolves the HEX color from its name. */
export default function (colorName: string | undefined) {
  if (colorName === undefined) {
    return scoreColors.grey;
  }
  const formattedName = colorName.toLowerCase().trim();
  return scoreColors[formattedName] ?? formattedName;
}
