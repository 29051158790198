<template>
  <div class="inline-table-wrapper">
    <table
      class="tw-w-full tw-border-separate tw-border-spacing-0 tw-overflow-hidden tw-rounded tw-border tw-border-background"
    >
      <slot></slot>
    </table>
  </div>
</template>

<style lang="scss" scoped>
:slotted(th) {
  @apply tw-text-subtitle-2 tw-h-[52px] tw-bg-background-medium tw-px-5 tw-pt-4 tw-pb-3 tw-text-secondary;
}

:slotted(td) {
  @apply tw-text-body-2 tw-px-5 tw-pt-[14px] tw-pb-[10px] tw-text-secondary;
}

:slotted(tbody > tr:not(:last-child) > td) {
  @apply tw-border-b tw-border-background;
}
</style>
