<template>
  <div class="tw-min-h-[48px] tw-bg-notify-light">
    <div class="tw-flex tw-h-full tw-items-start tw-px-6 tw-py-3">
      <div class="tw-flex-none tw-pr-4">
        <friss-icon
          class="tw-align-middle tw-text-notify"
          name="warning"
        ></friss-icon>
      </div>
      <div class="tw-text-body-1 tw-grow tw-pt-[1px] tw-text-secondary">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
