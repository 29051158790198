/** Returns an abbreviated number.
 *  E.g. (10110, 2) => 10.11K
 *       (1500, 0) => 2K
 *       (1e6, 0) => 1M
 */
export default function (value: number, decimalPrecision: number) {
  return Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: decimalPrecision,
    compactDisplay: 'short',
    useGrouping: false
  }).format(value);
}
