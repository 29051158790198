import i18next, { BackendModule, CustomTypeOptions, i18n } from 'i18next';

type ResourceStore = Record<
  string,
  Record<string, Record<string, string | object>>
>;
class SiteLocale {
  locale: i18n;
  /**
   * @param defaultLng Language that is set initially
   * @param supportedLngs List of supported language codes
   * @param resourceProvider Resource provider backend
   * @param defaultMessages Translations for the initial language
   */
  constructor(
    defaultLng: string,
    supportedLngs: string[],
    resourceProvider: BackendModule,
    defaultMessages: Record<string, string | object>
  ) {
    this.locale = i18next.createInstance();

    const resources: ResourceStore = {};
    resources[defaultLng] = {
      common: defaultMessages
    };

    const i18nextConfig: CustomTypeOptions = {
      initImmediate: false,
      debug: false,
      partialBundledLanguages: true,
      resources,
      supportedLngs,
      lng: defaultLng,
      fallbackLng: defaultLng,
      ns: ['common'],
      fallbackNS: 'common'
    };

    this.locale.use(resourceProvider).init(i18nextConfig);
  }

  async setLanguage(language: string) {
    await this.locale.loadLanguages([language]);
    await this.locale.changeLanguage(language);
    return language;
  }

  formatDateTime(value: Readonly<Date>) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    const formatter = new Intl.DateTimeFormat(this.locale.language, options);

    return formatter.format(value);
  }

  formatDate(value: Readonly<Date>) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    const formatter = new Intl.DateTimeFormat(this.locale.language, options);

    return formatter.format(value);
  }
}

export default SiteLocale;
