<template>
  <div
    class="expansion-panel tw-border-t tw-border-solid tw-border-background"
    :class="{ active: isActive, disabled: isDisabled }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExpansionPanel',

  inject: ['expansionPanels'],

  provide() {
    return {
      expansionPanel: this,
      message: this.isActive.toString()
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  emits: {
    /**
     * Emitted when the active panel is changed. Provides an isActive property
     * that indicates whether this panel is currently active or not.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'active-changed': ({ isActive: boolean }) => true
  },

  data() {
    return {
      isActive: false,
      content: null,
      header: null
    };
  },

  computed: {
    isDisabled() {
      return this.expansionPanels.disabled || this.disabled || !this.content;
    }
  },

  created() {
    this.expansionPanels.registerExpansionPanel(this);
  },

  methods: {
    toggle() {
      if (this.isDisabled) return;
      this.expansionPanels.toggle(this);
      this.$emit('active-changed', { isActive: this.isActive });
    },
    registerHeader(header) {
      this.header = header;
    },
    registerContent(content) {
      this.content = content;
    }
  }
});
</script>
