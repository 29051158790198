import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-32465312"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "tw-relative tw-mx-auto tw-h-full" };
const _hoisted_2 = {
    class: "modal-dialog tw-relative tw-mt-12 tw-p-12",
    "data-test-id": "modal-dialog"
};
const _hoisted_3 = { class: "tw-text-h2" };
const _hoisted_4 = { class: "tw-text-body-1 tw-mt-4" };
const _hoisted_5 = { class: "tw-mt-12" };
const _hoisted_6 = { class: "actions-container tw-flex tw-justify-end tw-self-end" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonIcon = _resolveComponent("ButtonIcon");
    const _component_Card = _resolveComponent("Card");
    const _component_Overlay = _resolveComponent("Overlay");
    return (_openBlock(), _createBlock(_component_Overlay, null, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", {
                    class: "tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-z-modal tw-overflow-y-auto tw-outline-none",
                    "data-test-id": "modal",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.dismissIfOutsideCard && _ctx.dismissIfOutsideCard(...args)))
                }, [
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_Card, {
                            ref: "modalCard",
                            "data-test-id": "modal-card",
                            class: "tw-relative tw-mx-auto tw-p-6 tw-pt-5 tw-text-secondary tw-shadow max-sm:tw-w-full sm:tw-w-[600px]"
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_3, [
                                    _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                                ]),
                                _createElementVNode("div", _hoisted_4, [
                                    _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                                ]),
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_component_ButtonIcon, {
                                        size: "large",
                                        class: "tw-absolute -tw-top-10 -tw-right-10 tw-z-modal tw-bg-body tw-shadow",
                                        "icon-name": "cross",
                                        title: _ctx.$uilibLocalized('close'),
                                        "data-test-id": "modal-close",
                                        onClick: _withModifiers(_ctx.dismiss, ["prevent"])
                                    }, null, 8, ["title", "onClick"]),
                                    _createElementVNode("div", _hoisted_6, [
                                        _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                                    ])
                                ])
                            ]),
                            _: 3
                        }, 512)
                    ])
                ])
            ])
        ]),
        _: 3
    }));
}
