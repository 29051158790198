import './main.css';
import uiLibLocale from './locale';
import { installFrissIcons } from '@friss/icons';
export * from '@/models';
export * from '@/composables';
const requireComponent = require.context('@/components', true, /[\w-]+\.vue$/);
const requireDirective = require.context('@/directives', true, /[\w-]+\.ts$/);
const components = {};
requireComponent.keys().forEach((fileName) => {
    const name = fileName
        .split('/')
        ?.pop()
        ?.replace(/\.\w+$/, '');
    const componentName = 'F' + name;
    const component = requireComponent(fileName);
    components[componentName] = component.default || component;
});
const directives = {};
requireDirective.keys().forEach((fileName) => {
    const name = fileName
        .split('/')
        ?.at(-2)
        ?.replace(/\.\w+$/, '');
    if (!name)
        return;
    directives[name] = requireDirective(fileName).default;
});
const FrissUiLibPlugin = {
    install(app) {
        installFrissIcons();
        for (const [name, component] of Object.entries(components)) {
            app.component(name, component);
        }
        for (const [name, directive] of Object.entries(directives)) {
            app.directive(name, directive);
        }
    }
};
export { uiLibLocale, FrissUiLibPlugin, components, directives };
