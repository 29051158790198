/** Returns true if value is null or undefined. Returs false otherwise. */
export const isNullish = function (val: unknown): val is null | undefined {
  return val === null || val === undefined;
};

/** Returns true if value is null, undefined or an empty array. Returns false otherwise. */
export const isNullishOrEmpty = function (
  val: unknown[] | null | undefined | string
): val is null | undefined | [] {
  return isNullish(val) || val.length === 0;
};
