import { computed, unref, watch } from 'vue';
/**
 * Reports changes to the dimensions of an Element's content or the border-box.
 *
 * @see {@link https://vueuse.org/useResizeObserver} & VueUse ResizeObserver.
 */
export function useResizeObserver(target, callback, options = {}) {
    const { ...observerOptions } = options;
    let observer;
    const cleanup = () => {
        if (observer) {
            observer.disconnect();
            observer = undefined;
        }
    };
    const targets = computed(() => Array.isArray(target) ? target.map((el) => unref(el)) : [unref(target)]);
    const stopWatch = watch(targets, (els) => {
        cleanup();
        observer = new ResizeObserver(callback);
        for (const _el of els)
            _el && observer.observe(_el, observerOptions);
    }, 
    // Change is immediate and fired once vue has done updated the component and browser DOM.
    // Deep: true allows us to watch an array of objects.
    { immediate: true, flush: 'post', deep: true });
    const stop = () => {
        cleanup();
        stopWatch();
    };
    return {
        stop
    };
}
