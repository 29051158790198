import { colors } from '@friss/design-system/colors';
export class Ripple {
    settings;
    element;
    constructor(el, settings) {
        this.settings = settings;
        this.element = el;
        el.style.position = 'relative';
        el.style.overflow = 'hidden';
        el.addEventListener('click', (event) => this.clickHandler(event));
    }
    clickHandler(event) {
        return this.create(event);
    }
    getRippleColor() {
        const defaultColor = `${colors.orangeLight}60`;
        return this.settings?.rippleColor ?? defaultColor;
    }
    calculateRipplePosition(event) {
        const rippledElement = event.currentTarget;
        const offset = rippledElement.getBoundingClientRect();
        const diameter = Math.max(rippledElement.clientWidth, rippledElement.clientHeight);
        const radius = diameter / 2;
        return {
            size: `${diameter}px`,
            left: `${event.clientX - offset.left - radius}px`,
            top: `${event.clientY - offset.top - radius}px`
        };
    }
    create(event) {
        const { size, left, top } = this.calculateRipplePosition(event);
        const circle = document.createElement('span');
        circle.style.width = circle.style.height = size;
        circle.style.left = left;
        circle.style.top = top;
        circle.classList.add('ripple');
        circle.style.backgroundColor = this.getRippleColor();
        const rippledElement = event.currentTarget;
        const ripple = rippledElement.getElementsByClassName('ripple')[0];
        if (ripple) {
            ripple.remove();
        }
        rippledElement.appendChild(circle);
    }
    destroy() {
        this.element.removeEventListener('click', this.clickHandler);
    }
}
/**
 * Ripple directive.
 * Applies a ripple effect when an HTML element is clicked.
 */
const RippleDirective = {
    mounted: (el, binding) => {
        el._ripple = new Ripple(el, binding.value);
    },
    beforeUnmount: (el) => {
        el._ripple?.destroy();
    }
};
export default RippleDirective;
