import getLanguageMajorVersion from './getLanguageMajorVersion';

/**
 * Gets preferred browser language setting if it is supported.
 * @param {Array.<String>} supportedLngs Array of supported language codes.
 * @param {String} fallbackLng Fallback language code that is used when the preferred language is not supported.
 * @returns Preferred language code if it is supported. Fallback language otherwise.
 */
export default function (supportedLngs: string[], fallbackLng: string) {
  const preferredLang = navigator.language;
  const preferredLangMajorVersion = getLanguageMajorVersion(preferredLang);

  if (supportedLngs.includes(preferredLang)) {
    return preferredLang;
  } else if (
    preferredLangMajorVersion &&
    supportedLngs.includes(preferredLangMajorVersion)
  ) {
    return preferredLangMajorVersion;
  } else {
    return fallbackLng;
  }
}
