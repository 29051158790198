import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_friss_icon = _resolveComponent("friss-icon");
    return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
        class: ["icon-btn tw-bg-transparent tw-flex tw-items-center tw-justify-center tw-rounded-full tw-p-0 tw-text-secondary tw-outline-none tw-transition-colors tw-duration-100 tw-ease-linear enabled:active:tw-bg-secondary enabled:active:tw-text-white disabled:tw-text-secondary-disabled", [_ctx.hoverVariant, _ctx.sizeVariants]],
        "data-test-id": "button-icon-btn"
    }), [
        _createVNode(_component_friss_icon, { name: _ctx.iconName }, null, 8, ["name"])
    ], 16));
}
