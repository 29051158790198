import uiLibLocale from '@/locale';
export default {
    methods: {
        /**
         * Returns localized key or a list of keys with a set of options.
         * @param keys Key or a list of keys to be translated.
         * @param options i18next localization options.
         */
        $uilibLocalized(keys, options) {
            return uiLibLocale.locale.t(keys, options ?? {});
        }
    }
};
