import { SiteLocale, resourcesToBackend } from '@friss/localization';
import en from './assets/locales/en.json';
import { SUPPORTED_LANGUAGES } from '@/config';
const resourceProvider = resourcesToBackend(async (language, _namespace, callback) => {
    try {
        const resources = await import(
        /* Eager mode forces webpack to bundle these imports in the main chunk. */
        /* Otherwise, it becomes hard to resolve these paths. */
        /* webpackMode: "eager" */
        `./assets/locales/${language}.json`);
        callback(null, resources.default);
    }
    catch (error) {
        callback(error, null);
    }
});
export default new SiteLocale('en', SUPPORTED_LANGUAGES, resourceProvider, en);
