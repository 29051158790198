<template>
  <div>
    <div
      v-if="expansionPanel.isActive"
      class="expansion-panel-content tw-pb-5 tw-pt-0 tw-text-secondary"
    >
      <div
        class="expansion-panel-content-wrap tw-text-body-2 tw-break-words"
        :class="{ 'tw-px-0': noGutters }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExpansionPanelContent',

  inject: ['expansionPanel'],

  props: {
    /** If true, content is full-width. Otherwise it is aligned with the header. */
    noGutters: { type: Boolean, default: false, required: false }
  },

  created() {
    this.expansionPanel.registerContent(this);
  }
});
</script>
