import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Button = _resolveComponent("Button");
    const _component_Modal = _resolveComponent("Modal");
    return (_openBlock(), _createBlock(_component_Modal, _mergeProps(_ctx.$attrs, { onDismiss: _ctx.dismiss }), {
        title: _withCtx(() => [
            _renderSlot(_ctx.$slots, "error")
        ]),
        content: _withCtx(() => [
            _renderSlot(_ctx.$slots, "description")
        ]),
        actions: _withCtx(() => [
            _createVNode(_component_Button, {
                type: "primary",
                "data-test-id": "error-alert-close-button",
                onClick: _ctx.dismiss
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$uilibLocalized('close')), 1)
                ]),
                _: 1
            }, 8, ["onClick"])
        ]),
        _: 3
    }, 16, ["onDismiss"]));
}
