<template>
  <div>
    <button
      class="tw-min-h-16 tw-item-center tw-text-body-1 tw-relative tw-flex tw-w-full tw-max-w-full tw-py-4 tw-text-left tw-text-secondary tw-outline-none"
      data-test-id="expansion-panel-toggle-chevron"
      @click="expansionPanel.toggle"
    >
      <div
        class="tw-mr-2 tw-max-w-[calc(100%-40px)] tw-flex-auto tw-break-words"
      >
        <slot></slot>
      </div>
      <div
        class="tw-flex-none"
        :class="{ 'tw-hidden': expansionPanel.isDisabled }"
      >
        <friss-icon
          name="arr-down-alt"
          class="tw-transition-transform tw-duration-200 tw-ease-linear"
          :class="{ '-tw-rotate-180': expansionPanel.isActive }"
        />
      </div>
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExpansionPanelHeader',

  inject: ['expansionPanel'],

  created() {
    this.expansionPanel.registerHeader(this);
  }
});
</script>
