import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-055d7a4c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "drawer tw-h-[100vh] tw-outline-none",
    "data-test-id": "drawer",
    tabindex: "0"
};
const _hoisted_2 = { class: "tw-grid tw-grid-cols-4 tw-gap-2 sm:tw-grid-cols-8 sm:tw-gap-2 md:tw-grid-cols-12 md:tw-gap-6" };
const _hoisted_3 = { class: "tw-col-span-4 sm:tw-col-span-6 sm:tw-col-start-2 md:tw-col-span-8 md:tw-col-start-3" };
const _hoisted_4 = { class: "drawer-header tw-mb-6 tw-flex tw-flex-row" };
const _hoisted_5 = { class: "tw-text-h2 tw-mr-6 tw-truncate sm:tw-mr-0" };
const _hoisted_6 = { class: "tw-text-body-1 tw-mt-4 tw-h-[calc(100vh-76px-24px)] tw-overflow-y-auto" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonIcon = _resolveComponent("ButtonIcon");
    const _component_Card = _resolveComponent("Card");
    const _component_DrawerTransition = _resolveComponent("DrawerTransition");
    return (_openBlock(), _createBlock(_component_DrawerTransition, null, {
        default: _withCtx(() => [
            (_ctx.show)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "tw-fixed tw-inset-0 tw-z-modal",
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.dismissIfOutsideCard && _ctx.dismissIfOutsideCard(...args)))
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_Card, {
                            ref: "drawerCard",
                            "data-test-id": "drawer-card",
                            class: "tw-relative tw-mx-auto tw-w-full tw-rounded-b-none tw-p-6 tw-pt-6 tw-text-secondary tw-shadow"
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, [
                                        _createElementVNode("div", _hoisted_4, [
                                            _createElementVNode("span", _hoisted_5, [
                                                _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                                            ]),
                                            _createVNode(_component_ButtonIcon, {
                                                size: "large",
                                                class: "tw-absolute tw-top-4 tw-right-4 tw-z-modal",
                                                "icon-name": "cross",
                                                title: _ctx.$uilibLocalized('close'),
                                                "data-test-id": "drawer-close",
                                                onClick: _ctx.dismiss
                                            }, null, 8, ["title", "onClick"])
                                        ]),
                                        _createElementVNode("div", _hoisted_6, [
                                            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                                        ])
                                    ])
                                ])
                            ]),
                            _: 3
                        }, 512)
                    ])
                ]))
                : _createCommentVNode("", true)
        ]),
        _: 3
    }));
}
