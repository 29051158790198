import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2e5b2aa2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "tw-w-full tw-rounded tw-bg-notify tw-py-4 tw-pl-3.5 tw-pr-3 tw-text-white" };
const _hoisted_2 = { class: "tw-flex tw-justify-between" };
const _hoisted_3 = { class: "tw-text-subtitle-2 tw-mr-4 tw-flex tw-content-center tw-self-center tw-text-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ButtonIcon = _resolveComponent("ButtonIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "default")
            ]),
            _createVNode(_component_ButtonIcon, {
                "icon-name": "cross",
                "dark-background": "",
                title: _ctx.$uilibLocalized('close'),
                class: "tw-flex-shrink-0 tw-flex-grow-0 tw-self-center !tw-text-white",
                "data-test-id": "snackbar-close-btn",
                onClick: _ctx.dismiss
            }, null, 8, ["title", "onClick"])
        ])
    ]));
}
