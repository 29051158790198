import { renderSlot as _renderSlot, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _directive_ripple = _resolveDirective("ripple");
    return _withDirectives((_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
        class: [
            _ctx.isPrimary
                ? `tw-bg-primary tw-text-white hover:tw-bg-primary-darker focus:tw-bg-primary-darker focus:tw-shadow-md active:tw-bg-primary-darker enabled:tw-text-shadow disabled:tw-bg-primary-disabled`
                : `tw-bg-white tw-text-secondary enabled:tw-btn-secondary-border enabled:hover:tw-btn-secondary-border-hover enabled:focus:tw-btn-secondary-border-focus enabled:focus:hover:tw-btn-secondary-border-hover disabled:tw-btn-secondary-border-disabled disabled:tw-text-secondary-disabled`,
            "tw-text-btn tw-box-border tw-h-10 tw-select-none tw-truncate tw-rounded tw-px-3.5 tw-py-2 focus:tw-outline-none"
        ]
    }), [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 16)), [
        [_directive_ripple, _ctx.rippleSettings]
    ]);
}
