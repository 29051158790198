<template>
  <div class="expansion-panels">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ExpansionPanels',

  provide() {
    return {
      expansionPanels: this
    };
  },

  props: {
    /** Allow expanding multiple panels at the same time. */
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: {
    /** Emitted when the active panel in this group is changed. */
    'active-changed': () => true
  },

  data() {
    return {
      expansionPanelItems: []
    };
  },

  methods: {
    registerExpansionPanel(panel) {
      this.expansionPanelItems.push(panel);
    },
    toggle(panel) {
      if (this.multiple) {
        panel.isActive = !panel.isActive;
      } else {
        this.expansionPanelItems.forEach((item) => {
          if (item === panel) panel.isActive = !panel.isActive;
          else item.isActive = false;
        });
      }
      this.$emit('active-changed');
    }
  }
});
</script>

<style lang="scss" scoped>
.expansion-panels {
  & > .expansion-panel {
    @apply tw-border-t tw-border-solid tw-border-background;

    &.expansion-panel:last-of-type {
      @apply tw-border-b tw-border-solid tw-border-background;
    }
  }
}
</style>
