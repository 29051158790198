import getBrowserLanguage from './src/getBrowserLanguage';
import getLanguageMajorVersion from './src/getLanguageMajorVersion';
import SiteLocale from './src/siteLocale';
import resourcesToBackend from 'i18next-resources-to-backend';

export {
  SiteLocale,
  getBrowserLanguage,
  getLanguageMajorVersion,
  resourcesToBackend
};
