import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-91435226"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["tabindex"];
const _hoisted_2 = ["title"];
const _hoisted_3 = ["title"];
const _hoisted_4 = { class: "tw-text-subtitle-2 tw-ml-4 tw-self-center tw-truncate" };
const _hoisted_5 = {
    key: 0,
    class: "tw-absolute tw-top-12 tw-z-dropdown tw-w-full tw-overflow-hidden tw-rounded-sm tw-bg-body tw-text-secondary tw-shadow-lg",
    "data-test-id": "select-box-options"
};
const _hoisted_6 = ["onMousedown", "onMouseenter"];
const _hoisted_7 = { class: "tw-text-body-2 tw-self-center tw-truncate" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_friss_icon = _resolveComponent("friss-icon");
    return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
        ref: "selectBox",
        class: ["tw-group tw-relative tw-box-border tw-h-12 tw-w-full tw-min-w-[64px] tw-max-w-full tw-select-none tw-rounded tw-text-left tw-leading-[3rem] tw-outline-none", { 'tw-cursor-pointer': !_ctx.disabled }],
        tabindex: _ctx.tabindex,
        onKeydown: _cache[1] || (_cache[1] =
            //@ts-ignore
            (...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
        onFocus: _cache[2] || (_cache[2] =
            //@ts-ignore
            (...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[3] || (_cache[3] =
            //@ts-ignore
            (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }), [
        _createElementVNode("fieldset", {
            class: _normalizeClass(["tw-flex tw-h-full tw-w-full tw-min-w-[64px] tw-max-w-full tw-rounded tw-border tw-border-solid tw-border-background-darker tw-bg-body tw-text-secondary", {
                    'tw-bg-background-medium': _ctx.isOpen,
                    'tw-bg-background-disabled tw-text-secondary-disabled': _ctx.disabled,
                    'tw-text-secondary-medium group-hover:tw-border-secondary-medium group-focus:tw-border-secondary-medium': !_ctx.disabled
                }]),
            title: _ctx.selectedText,
            "data-test-id": "select-box",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
        }, [
            _createElementVNode("legend", {
                class: "tw-ml-2 tw-max-w-[calc(100%-1rem)]",
                style: _normalizeStyle({ width: _ctx.isLabelVisible ? _ctx.labelWidth : '0px' })
            }, null, 4),
            _createElementVNode("span", {
                ref: "labelContentMeasure",
                class: "tw-text-nowrap tw-text-caption tw-invisible tw-absolute tw-max-w-[calc(100%-1rem)] tw-self-center tw-truncate"
            }, _toDisplayString(_ctx.label), 513),
            _createElementVNode("label", {
                ref: "label",
                title: _ctx.label,
                class: _normalizeClass(["tw-text-nowrap tw-max-w-[calc(100%-1rem)] tw-self-center tw-truncate", {
                        'tw-text-body-2 tw-ml-4': !_ctx.isLabelVisible,
                        'tw-text-caption tw-absolute tw-left-2 -tw-top-2.5 tw-px-1': _ctx.isLabelVisible
                    }])
            }, _toDisplayString(_ctx.label), 11, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.selectedText), 1)
            ]),
            _createVNode(_component_friss_icon, {
                class: _normalizeClass(["tw-ml-auto tw-mr-5 tw-self-center tw-text-secondary tw-transition-transform tw-duration-200 tw-ease-linear", { '-tw-rotate-180': _ctx.isOpen }]),
                name: "arr-small-down-alt"
            }, null, 8, ["class"])
        ], 10, _hoisted_2),
        (_ctx.isOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: option,
                        class: _normalizeClass(["tw-flex tw-h-8 tw-px-3", {
                                item__active: _ctx.activeOptionIndex === i,
                                item__selected: _ctx.selectedOptionIndex === i
                            }]),
                        onMousedown: _withModifiers(($event) => (_ctx.select(i)), ["left"]),
                        onMouseenter: ($event) => (_ctx.activate(i))
                    }, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(option), 1),
                        (i === _ctx.selectedOptionIndex)
                            ? (_openBlock(), _createBlock(_component_friss_icon, {
                                key: 0,
                                class: "tw-ml-auto tw-mr-2 tw-self-center tw-text-primary",
                                name: "check"
                            }))
                            : _createCommentVNode("", true)
                    ], 42, _hoisted_6));
                }), 128))
            ]))
            : _createCommentVNode("", true)
    ], 16, _hoisted_1));
}
